export function oneOf (value, validList) {
    for (let i = 0; i < validList.length; i++) {
      if (value === validList[i]) {
        return true
      }
    }
    return false
  }

  // 上传OSS
export const getSuffix = (filename) => {
    var index1=filename.lastIndexOf(".");
    var index2=filename.length;
    var suffix=filename.substring(index1+1,index2);
    return suffix;
  }
