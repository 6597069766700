<template>
  <div class="t_content">
    <div style="margin: 20px;">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="任务id">
          <el-input v-model="formInline.id" placeholder="任务id"></el-input>
        </el-form-item>
        <!-- <el-form-item label="任务名">
          <el-input v-model="formInline.name" placeholder="任务名"></el-input>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="handleSearch">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleAdd">新增</el-button>
        </el-form-item>
      </el-form>
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane v-for="v in dataTab" :key="v.value" :label="v.key" :name="v.value">
          <el-table :data="tabbleData" empty-text="暂无数据" border style="width: 100%;">   
            <el-table-column prop="id" label="任务id"></el-table-column>
            <el-table-column label="头图" width="120">
              <template slot-scope="scope">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="scope.row.pictureUrl"
                  fit="cover"></el-image>
              </template>
            </el-table-column>
            
            <el-table-column prop="name" label="任务名"></el-table-column>
            <el-table-column prop="describe" label="任务简介"></el-table-column>
            <el-table-column prop="score" label="任务收益"></el-table-column>
            <el-table-column prop="platform" label="任务平台"></el-table-column>
            <el-table-column fixed="right" label="操作" width="100">
              <template slot-scope="scope">
                <el-button slot="reference" type="primary" size="small" @click="bianjie(scope.row)">编辑</el-button>
                <span style="margin-left: 10px;"></span>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination 
            :page-size="pagination.pageSize" 
            :pager-count="11" 
            layout="prev, pager, next" 
            :total="pagination.total"
            @next-click="nextPageUser"
            @prev-click="pervPageUser"
            @current-change="currentpagenum"
            >
          </el-pagination>
        </el-tab-pane>
      </el-tabs>
    </div>

    <el-dialog
      title="编辑任务"
      :visible.sync="showTaskEdit"
      width="70%"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      >
      <div v-if="showTaskEdit">
        <el-form ref="form" :model="taskFormData" label-width="120px">
          <el-form-item label="任务图片">
            <image-upload :images="taskFormData.pictureUrl ? [{url:taskFormData.pictureUrl}] : []" :imagesChange="imagesChange" :limit="1" />
          </el-form-item>
          <el-form-item label="任务名">
            <el-input v-model="taskFormData.name"></el-input>
          </el-form-item>
          <el-form-item label="任务副标题">
            <el-input type="textarea" v-model="taskFormData.describe"></el-input>
          </el-form-item>
          <el-form-item label="任务链接">
            <el-input type="textarea" v-model="taskFormData.urlLink"></el-input>
          </el-form-item>
          <el-form-item label="任务详情">
            <editor :value="taskFormData.require" :ajaxEnd="-1" :cache="false" eid="1" @on-change="changeDetailsEditor"></editor>
          </el-form-item>
          <el-form-item  label="任务积分">
            <el-input style="width: 100px;" v-model="taskFormData.score"></el-input>
          </el-form-item>
          <el-form-item label="任务平台">
            <el-select v-model="taskFormData.platform" placeholder="请选择">
              <el-option
                v-for="item in pingtaioptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="任务类型">
            <el-radio v-model="taskFormData.type" label="1">普通任务</el-radio>
            <el-radio v-model="taskFormData.type" label="2">周任务</el-radio>
          </el-form-item>
          <el-form-item label="每周次数" v-if="(taskFormData.type=='2')">
            <el-input style="width: 100px;" v-model="taskFormData.num"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="baocunshuju">保存</el-button>
            <el-button @click="quxiaoshujuhuifu">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import imageUpload from '../../components/image-upload.vue'
import { POST } from '../../api/index'
import editor from "../../components/editor/editor.vue"
export default {
  components: {
    'image-upload': imageUpload,
    editor: editor
  },
  data() {
    return {
      dataTab: [
        {
          key: '全部',
          value: 'all',
        }, {
          key: '普通任务',
          value: '1',
        }, {
          key: '周任务',
          value: '2',
        }, 
      ],
      showTaskEdit: false,
      formInline: {
        name: '',
        id: '',
      },
      pingtaioptions: [
        {
          value: 'weibo',
          label: '微博',
        }, {
          value: 'bilibili',
          label: 'b站',
        }, {
          value: 'redbook',
          label: '小红书',
        }, 
      ],
      activeName: 'all',
      taskFormData: {
        id: 0,
        pictureUrl: '',
        name: '',
        describe: '',
        score: 0,
        require: '',
        num: 1,
        platform: '',
        urlLink: '',
        type: '1'
      },
      tabbleData: [],
      pagination: {
        total: 0,
        pageSize: 5,
        pageNum: 1,
      }
    }
  },
  created() {
    this.getTaskList()
  },
  methods: {
    changeDetailsEditor(e) {
      this.taskFormData.require = e
    },
    async baocunshuju() {
      if (this.shujupanduan()) return
      const { pictureUrl, name, id, describe, score, require, num, platform, type, urlLink } = this.taskFormData
      let url = 'admin/saveTask'
      const body = {
          describe,
          pictureUrl,
          name,
          score,
          require,
          num,
          platform,
          type,
          urlLink
        }
      if (id != 0) {
        body.id = id
        url = 'admin/saveTask'
      }
      const res = await POST({
        url, 
        body
      })
      console.log(res)
      if (res.status == 200) {
        this.quxiaoshujuhuifu()
        this.getTaskList()
      } else {
        this.$message.error(res.msg);
      }
    },
    quxiaoshujuhuifu() {
      this.taskFormData = {
        id: 0,
        pictureUrl: '',
        name: '',
        describe: '',
        score: 0,
        require: '',
        num: 1,
        platform: '',
        urlLink: '',
        type: '1'
      }
      this.showTaskEdit = false
    },
    shujupanduan() {
      if (!this.taskFormData.name) {
        this.$message.error('请输入任务名称');
        return true
      }
      if (!this.taskFormData.describe) {
        this.$message.error('请输入任务描述');
        return true
      }
      if (!this.taskFormData.pictureUrl) {
        this.$message.error('请上传任务图片');
        return true
      }
      if (!this.taskFormData.require) {
        this.$message.error('请输入任务详情');
        return true
      }
      if (!this.taskFormData.score) {
        this.$message.error('请输入任务积分');
        return true
      }
      return false
    },
    currentpagenum(e) {
      this.pagination.pageNum = e
      this.getTaskList()
    },
    nextPageUser() {
      this.pagination.pageNum++
      this.getTaskList()
    },
    pervPageUser() {
      this.pagination.pageNum--
      this.getTaskList()
    },
    async getTaskList() {
      let body = {
        pageNum: this.pagination.pageNum,
        pageSize: this.pagination.pageSize,
      }
      if (this.formInline.id) {
        body.taskId = this.formInline.id
      }
      if (this.formInline.name) {
        body.taskName = this.formInline.name
      }
      if (this.activeName != 'all') {
        body.type = this.activeName
      }
      const res = await POST({
        url: 'admin/queryTask', 
        body,
      })
      if (res.status == 200) {
        this.tabbleData = res.content.list
        this.pagination.total = res.content.total
      } else {
        this.$message.error(res.msg);
      }
    },
    handleSearch() {
      this.tabbleData = []
      this.pagination.pageNum = 1
      this.getTaskList()
    },
    handleAdd() {this.showTaskEdit = true},
    handleClick() {
      this.tabbleData = []
      this.pagination.pageNum = 1
      this.getTaskList()
    },
    bianjie(e) {
      e.type = e.type + ''
      this.taskFormData = e
      this.showTaskEdit = true
    },
    imagesChange(images) {
      if(images.length) {
        this.taskFormData.pictureUrl = images[0].url
      }
    },
  },
}
</script>

<style>
.t_content {
  width: 100%;
}
</style>