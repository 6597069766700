<template>
  <div>
    <el-upload
      :action="ossHost"
      :data="ossData"
      :file-list="pimages"
      :accept="'image'"
      list-type="picture-card"
      :before-upload="getOssConfig"
      :on-success="updataSuccess"
      :on-preview="handlePictureCardPreview"
      :on-remove="removeimage"
      :limit="limit"
      :on-error="updataError"
      >
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog :visible.sync="imagePreview" append-to-body>
      <img width="100%" :src="imagePreviewUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import { POST } from './../api/index'
export default {
  name: 'image-upload',
  props: {
    images: Array,
    limit: Number,
    imagesChange: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      pimages: [],
      imagePreview: false,
      imagePreviewUrl: '',
      ossHost: '',
      ossData: {},
    }
  },
  created() {
    this.pimages = this.images
  },
  methods: {
    handlePictureCardPreview(file) {
      this.imagePreviewUrl = file.url;
      this.imagePreview = true;
    },
    updataError() {
      this.$message({
        message: "上传失败",
        type: "error",
        center: true,
      });
    },
    removeimage(file, fileList) {
      console.log(file, fileList)
      this.pimages = fileList
      let ls = []
      fileList.forEach(v => {
        ls.push({
          url: v.url
        })
      });
      this.imagesChange(ls)
    },
    async getOssConfig(file) {
      let extensionList = [
        "png",
        "PNG",
        "jpg",
        "JPG",
        "jpeg",
        "JPEG",
      ];
      const index = file.name.lastIndexOf(".")

      if (extensionList.indexOf(file.name.substr(index+1)) < 0) {
        this.$message({
          message: "当前文件格式不支持",
          type: "error",
          center: true,
        });
        return false;
      }
      const res = await POST({url: 'api/common/ossUpload', body: {}})
      this.ossHost = res.content.host
      const { policy, accessid, signature, dir, filename } = res.content
      const fn = dir + filename + '.' + file.name.substr(index+1)

      this.ossData = {
        Filename: fn,
        key: fn,
        policy,
        ossaccessKeyId: accessid,
        signature,
      }
      return true
    },
    updataSuccess(response, file, fileList) {
      console.log(response, file, fileList)
      fileList[fileList.length-1].url = this.ossHost + '/' + this.ossData.key
      this.pimages = fileList
      let ls = []
      fileList.forEach(v => {
        ls.push({
          url: v.url
        })
      });
      
      this.imagesChange(ls)
    },
  }
}
</script>