<template>
  <div :class="'editor-wrapper ' + className">
    <div class="box" :style="'height:' + clientHeight + 'px'">
      <i :class="className == '' ? 'el-icon-full-screen' : 'el-icon-close'"
        style="position:absolute;right:10px;top:10px;font-size:18px;cursor:pointer;color:#000;" @click="setFull"></i>
      <div :id="editorId" :style="'height:' + (clientHeight - 40) + 'px'"></div>
    </div>
    <div class="bg" :style="'height:' + clientHeight + 'px'"></div>
    <slot></slot>
  </div>
</template>

<script>
import axios from 'axios'
import Editor from './wangEditor.js'
import './wangEditor.css'
import { POST } from '../../api/index'
import { oneOf, getSuffix } from './editor_tool'
var cachei = 0
export default {
  name: 'Editor',
  props: {
    value: {
      type: String,
      default: ''
    },
    ajaxEnd: {
      type: Number,
      default: 9
    },
    valueType: {
      type: String,
      default: 'html',
      validator: (val) => {
        return oneOf(val, ['html', 'text'])
      }
    },
    /**
     * @description 是否开启本地存储
     */
    cache: {
      type: Boolean,
      default: true
    },
    eid: {
      type: String,
      default: ''
    },
    minh: {
      type: Number,
      default: 340
    }
  },
  data() {
    return {
      ossConfig: {
        dir: '',
        filename: '',
        policy: '',
        accessid: '',
        host: '',
        signature: '',
        style: ''
      },
      filenameNum: 0,
      className: '',
      clientHeight: `${this.minh}`,
      html: ''
    }
  },
  computed: {
    editorId() {
      return 'editor' + this.eid
    }
  },
  watch: {
    ajaxEnd() {
      //   console.log(val,oldval)
      let html = this.value
      if (this.editor) {
        this.editor.txt.html(html)
      } else {
        this.createEditor(html)
        this.editor.txt.html(html)
      }
      this.html = html
    }
  },
  methods: {
    createEditor(html) {
      var that = this
      this.editor = new Editor('#' + this.editorId)
      this.editor.customConfig.onchangeTimeout = 100;
      this.editor.customConfig.onchange = (html) => {

        var json = this.editor.txt.getJSON()
        let cacheName = this.editorId
        localStorage[cacheName] = html

        this.html = html
        this.$emit('on-change', html, json)
      }

      this.editor.customConfig.uploadImgShowBase64 = true // base 64 存储图片
      this.editor.customConfig.uploadImgServer = that.ossConfig.host// 配置服务器端地址
      // this.editor.customConfig.uploadImgHeaders = {}// 自定义 header
      this.editor.customConfig.uploadFileName = 'file' // 后端接受上传文件的参数名
      this.editor.customConfig.uploadImgMaxSize = 2 * 1024 * 1024 // 将图片大小限制为 2M
      this.editor.customConfig.uploadImgMaxLength = 6 // 限制一次最多上传 3 张图片
      this.editor.customConfig.uploadImgTimeout = 3 * 60 * 1000 // 设置超时时间
      // 关闭粘贴样式的过滤
      this.editor.customConfig.pasteFilterStyle = true
      // 忽略粘贴内容中的图片
      this.editor.customConfig.pasteIgnoreImg = false
      this.editor.customConfig.zIndex = 100

      // 自定义处理粘贴的文本内容
      this.editor.customConfig.pasteTextHandle = function (html) {
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回

        // content = content.replace(/div[^>]*>/g,'p>')
        // return content.replace(/<(?!img|br|\/br|p|\/p).*?>/g, "")

        html = html.replace(/(<!--.*?-->)/g, "")
        html = html.replace(/<xml>(?:(?!<\/xml>)[\s\S])*<\/xml>/gi, "")
        html = html.replace(/<style>(?:(?!<\/style>)[\s\S])*<\/style>/gi, "")
        html = html.replace(/div[^>]*>/g, 'p>')
        html = html.replace(/<(?!br|\/br|p|\/p)[^>]*>|<\/(?!img|br|\/br|p|\/p)[^>]*>/g, "")
        html = html.replace(/[\r\n]/g, "")

        return html

        //return html
        //return content.replace(/<[^>]*>|<\/[^>]*>/gm, "")
      }
      // this.editor.customConfig.debug=true
      // 配置菜单
      this.editor.customConfig.menus = [
        'head', // 标题
        'bold', // 粗体
        'fontSize', // 字号
        //'fontSm', // 字号 开发中。。。

        'italic', // 斜体
        'underline', // 下划线
        'strikeThrough', // 删除线
        'foreColor', // 文字颜色
        'backColor', // 背景颜色
        'link', // 插入链接
        'list', // 列表
        'justify', // 对齐方式
        // 'quote', // 引用
        // 'emoticon', // 表情
        'image', // 插入图片
        // 'table', // 表格
        //'video', // 插入视频
        // 'code', // 插入代码
        'undo', // 撤销
        'redo', // 重复
        'formatHtml', // 格式化
      ]

      this.editor.customConfig.customUploadImg = function (files, insert) {
        that.filenameNum++
        var form = new FormData();
        that.ossConfig.filename = that.ossConfig.filename + that.filenameNum
        var suffix = getSuffix(files[0]['name']);
        var filepath = that.ossConfig.dir + that.ossConfig.filename + '.' + suffix;
        form.append('Filename', filepath);
        form.append('key', filepath);
        form.append('policy', that.ossConfig.policy);
        form.append('OSSAccessKeyId', that.ossConfig.accessid);
        form.append('success_action_status', '200');
        form.append('signature', that.ossConfig.signature);
        form.append('file', files[0])

        var imgUrl = that.ossConfig.host + '/' + filepath
        axios({
          url: that.ossConfig.host,
          method: 'post',
          data: form,
          headers: { 'Content-Type': 'multipart/form-data' }
        })
          .then(() => {
            insert(imgUrl)
          })
      }

      this.editor.create()
      this.editor.txt.html(html)
      this.html = html
    },
    async getOssSign() {
      const res = await POST({ url: 'api/common/ossUpload', body: {} })
      this.ossConfig = res.content
      this.createEditor(this.value)
    },
    setFull() {
      if (this.className == '') {
        this.className = 'full'
        this.clientHeight = `${document.documentElement.clientHeight}`
      } else {
        this.className = ''
        this.clientHeight = `${this.minh}`
      }
    },

    loadHistoryContent() {
      let cacheName = this.editorId
      this.$Modal.confirm({
        title: '提示',
        content: '<p>确认从缓存中加载内容到编辑器中。<br><b>缓存内容如下：</b><div style="width:300px;height:300px;overflow:auto;background:#f9f9f9;padding:5px;border:1px #ddd solid;">' + localStorage[cacheName] + '</div></p>',
        onOk: () => {
          let html = localStorage[cacheName]
          this.editor.txt.html(html)
        },
        onCancle: () => {

        }
      })
    },
    setCache() {
      if (cachei > 9) cachei = 0
      let cacheName = this.editorId
      cacheName = cacheName + '-' + cachei
      localStorage[cacheName] = this.editor.txt.html()
      cachei++
    }
  },
  mounted() {
    this.getOssSign()
    this.clientHeight = `${this.minh}`
    let cacheName = this.editorId
    localStorage[cacheName] = ''
  },
  created() {
    // let that = this
    // setInterval(function(){
    //   that.setCache()
    // },30000)

    // let _this = this
    // window.onbeforeunload = function (e) {
    //   //if (_this.$route.name == "dyyPerformanceCenterSale") {
    //     e = e || window.event;
    //     // 兼容IE8和Firefox 4之前的版本
    //     if (e) {
    //       e.returnValue = '关闭提示1111';
    //     }
    //     // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
    //     return '关闭提示222';
    //   // } else {
    //   //   window.onbeforeunload = null
    //   // }
    // }
  }
}
</script>

<style>
.w-e-toolbar {
  background: #f8f8f9 !important;
  border-color: #ddd !important;
  height: 40px;
  line-height: 30px;
}

.w-e-text-container {
  border-color: #ddd !important;
}

.bg {
  position: absolute;
  width: 100%;
}

.full {
  position: fixed;
  z-index: 9999;
  height: 800px;
  width: 100%;
  top: 0;
  left: 0;
}

.full .bg {
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #000;
  z-index: 998;
}

.full .box {
  position: absolute;
  left: 0;
  width: 100%;
  margin-left: 2px;
  z-index: 9999;
  background: #fff;
}

.full .w-e-toolbar {
  width: 100%;
}

.full .w-e-text-container {
  height: 100% !important;
  width: 100%px;
}

.full .w-e-text {
  padding: 20px;
}

.w-e-text {
  padding: 10px;
  color: #6f6f6f;
  line-height: 200%;
}

.box {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #ddd;
}
</style>
