<template>
  <div class="p_content">
    <!-- <img src="https://farida.oss-cn-hangzhou.aliyuncs.com/data/20221206/16702961450254699.png" alt="" /> -->
    <div style="margin: 20px;">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="商品id">
          <el-input v-model="formInline.id" placeholder="商品id"></el-input>
        </el-form-item>
        <el-form-item label="商品名">
          <el-input v-model="formInline.name" placeholder="商品名"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleAdd">新增</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-table :data="tabledata" empty-text="暂无数据" :row-style="biaostyle" border>   
      <el-table-column prop="id" label="商品id"></el-table-column>
      <el-table-column label="头图" width="120">
        <template slot-scope="scope">
          <el-image
            v-if="scope.row.images.length"
            style="width: 100px; height: 100px"
            :src="scope.row.images[0].url"
            fit="cover"></el-image>
        </template>
      </el-table-column>
      
      <el-table-column prop="name" label="商品名"></el-table-column>
      <el-table-column prop="describe" label="商品描述"></el-table-column>
      <el-table-column prop="score" label="售卖价格"></el-table-column>
      <el-table-column prop="stock" label="当前库存"></el-table-column>
      <el-table-column fixed="right" label="操作" width="300"><template slot-scope="scope">
          <el-button slot="reference" type="primary" @click="bianjiProductDetail(scope.row)">编辑</el-button>
          <span style="margin-left: 10px;"></span>
          <!-- <el-popconfirm title="确定下架该商品？" v-if="scope.row.sellType == 'sell'" @confirm="handleselltype(scope.row)"> 
            <el-button slot="reference" type="danger" size="small">下架</el-button>
          </el-popconfirm>
         
          <el-popconfirm title="确定上架该商品？" v-if="scope.row.sellType == 'xiajia'" @confirm="handleselltype(scope.row)"> 
            <el-button slot="reference" type="warning" size="small">上架</el-button>
          </el-popconfirm> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination 
      :page-size="pagination.pageSize" 
      :pager-count="11" 
      layout="prev, pager, next" 
      :total="pagination.total"
      @next-click="nextPageUser()"
      @prev-click="pervPageUser()"
      @current-change="currentpagenum"
      >
    </el-pagination>

  
  
    <el-dialog
      title="编辑商品"
      :visible.sync="showProductEdit"
      width="70%"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      >
      <div v-if="showProductEdit">
        <el-form ref="form" :model="productFormData" label-width="80px">
          <el-form-item label="商品名">
            <el-input v-model="productFormData.name"></el-input>
          </el-form-item>
          <el-form-item label="商品描述">
            <el-input type="textarea" v-model="productFormData.describe"></el-input>
          </el-form-item>
          <el-form-item label="商品图片">
            <image-upload :images="productFormData.images" :imagesChange="imagesChange" :limit="9" />
          </el-form-item>
          <el-form-item  label="商品积分">
            <el-input style="width: 100px;" v-model="productFormData.score"></el-input>
          </el-form-item>
          <el-form-item label="商品库存">
            <el-input style="width: 100px;" v-model="productFormData.stock"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="baocunshangpin">保存</el-button>
            <el-button @click="quxiaoanniu">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    
  </div>
</template>

<script>
import imageUpload from '../../components/image-upload.vue'
import { POST } from '../../api/index'
export default {
  components: {
    'image-upload': imageUpload
  },
  data() {
    return {
      imagePreview: false,
      imagePreviewUrl: '',
      showProductEdit: false,
      formInline: {
        name: '',
        id: '',
      },
      tabledata: [],
      productjuluShow: false,
      ossHost: '',
      ossData: {},
      productFormData: {
        id: 0,
        images: [],
        name: '',
        describe: '',
        score: 0,
        stock: 0,
      },
      pagination: {
        total: 0,
        pageSize: 10,
        pageNum: 1,
      }
    }
  },
  async created() {
    this.getProductList()
  },
  methods: {
    currentpagenum(e) {
      this.pagination.pageNum = e
      this.getProductList()
    },
    nextPageUser() {
      this.pagination.pageNum++
      this.getProductList()
    },
    pervPageUser() {
      this.pagination.pageNum--
      this.getProductList()
    },
    quxiaoanniu() {
      this.showProductEdit = false
      this.productFormData = {
          id: 0,
          images: [],
          name: '',
          describe: '',
          score: 0,
          stock: 0,
        }
    },
    imagesChange(images) {
      this.productFormData.images = images
    },
    handleselltype(e) {
      console.log(e)
    },
    biaostyle(e) {
      if (e.row.score == 0) {
        return {
          background: '#f1f2f5'
        }
      }
    },
    handleSearch() {
      this.tabledata = []
      this.productFormData = {
        id: 0,
        images: [],
        name: '',
        describe: '',
        score: 0,
        stock: 0,
      }
      this.getProductList()
    },
    bianjiProductDetail(row) {
      this.productFormData = row
      this.showProductEdit = true
    },
    handleAdd() {
      this.showProductEdit = true
    },
    async getProductList() {
      const body = {
          pageNum: this.pagination.pageNum,
          pageSize: this.pagination.pageSize,
        }
      if (this.formInline.id) {
        body.goodsId = this.formInline.id
      }
      if (this.formInline.name) {
        body.goodsName = this.formInline.name
      }
      const res = await POST({
        url: 'api/common/queryGoodsList', 
        body,
      })
      if (res.status == 200) {
        res.content.list.forEach(v => {
          console.log(v.pictureUrl.split(',').map(v => {return {url: v}}))
          if (v.pictureUrl) {
            v.images = v.pictureUrl.split(',').map(v => {return {url: v}})
          } else {
            v.images = []
          }
        });
        this.tabledata = res.content.list
        this.pagination.total = res.content.total
      } else {
        this.$message.error(res.msg);
      }
    },
    async baocunshangpin() {
      if (this.shujupanduan()) return
      let url = 'admin/saveGoods'
      const body = {
        name: this.productFormData.name,
        pictureUrl: (this.productFormData.images.map(v => v.url)).join(','),
        describe: this.productFormData.describe,
        stock: this.productFormData.stock,
        score: this.productFormData.score,
        deleted: 0,

      }
      if (this.productFormData.id) {
        body.id = this.productFormData.id
        url = 'admin/updateGoods'
      }

      const res1 = await POST({
        url, 
        body,
      })
      if (res1.status == 200) {
        this.$message.success(res1.msg);
        this.showProductEdit = false
        this.productFormData = {
          id: 0,
          images: [],
          name: '',
          describe: '',
          score: 0,
          stock: 0,
        }
        this.getProductList()
      } else {
        this.$message.error(res1.msg);
      }
    },
    shujupanduan() {
      if (!this.productFormData.name) {
        this.$message.error('请输入商品名称');
        return true
      }
      if (!this.productFormData.describe) {
        this.$message.error('请输入商品描述');
        return true
      }
      if (!this.productFormData.images.length) {
        this.$message.error('请上传商品图片');
        return true
      }
      if (!this.productFormData.score) {
        this.$message.error('请输入商品积分');
        return true
      }
      if (this.productFormData.stock.toString() == '') {
        this.$message.error('请输入商品库存');
        return true
      }
      return false
    },

  }
}
</script>
<style>
.p_content {
  width: 100%;
}
</style>