<template>
  <div class="user_content">
    <div style="margin: 20px;">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="用户id">
          <el-input v-model="formInline.userid" placeholder="用户id"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getUserList">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" empty-text="暂无数据" :row-style="biaostyle" border style="width: 100%;">   
      <el-table-column prop="id" label="用户id"></el-table-column>
      <el-table-column label="头像" :width="120">
        <template slot-scope="scope">
          <el-image
            style="width: 100px; height: 100px"
            :src="scope.row.pictureUrl"
            fit="cover"></el-image>
        </template>
      </el-table-column>
      
      <el-table-column prop="nick" label="姓名"></el-table-column>
      <el-table-column prop="regionName" label="地区"></el-table-column>
      <el-table-column prop="score" label="当前积分"></el-table-column>
      <el-table-column prop="allScore" label="总积分"></el-table-column>
      <el-table-column fixed="right" label="操作" width="200"><template slot-scope="scope">
          <el-popconfirm v-if="!scope.row.deleted" title="确定拉黑这个人吗？" @confirm="handleLahei(scope.row)"> 
            <el-button slot="reference" type="danger" size="small">拉黑</el-button>
          </el-popconfirm>
          <el-popconfirm v-if="scope.row.deleted" title="确定取消拉黑这个人吗？" @confirm="handlequxiaoLahei(scope.row)"> 
            <el-button slot="reference" type="warning" size="small" >取消拉黑</el-button>
          </el-popconfirm>
          <span style="margin-left: 10px;"></span>
          <el-button @click="getUserDetail(scope.row)" type="primary" size="small" >查看</el-button>
          
        </template>
      </el-table-column>
    </el-table>
    <el-pagination 
      :page-size="pagination.pageSize" 
      :pager-count="11" 
      layout="prev, pager, next" 
      :total="pagination.total"
      @next-click="nextPageUser()"
      @prev-click="pervPageUser()"
      @current-change="currentpagenum"
      >
    </el-pagination>


    <el-dialog
      title="用户信息"
      :visible.sync="showUserJifen"
      width="70%"
      :before-close="() => {showUserJifen = false; userinfotabname = 'jifen'}">
      <div style="display: flex;align-items: center;">
        <el-image
          style="width: 100px; height: 100px"
          :src="userjifendetail.pictureUrl"
          fit="cover"></el-image>
        <span style="margin-left: 10px;">{{userjifendetail.name}}</span>
        <span style="margin-left: 30px;">地区：{{userjifendetail.regionName}}</span>
        <span style="margin-left: 30px;">当前积分：{{userjifendetail.score}}</span>
        <span style="margin-left: 30px;">总积分：{{userjifendetail.allScore}}</span>
      </div>
      <el-tabs v-model="userinfotabname" type="card" @tab-click="handleClickuserinfotab" style="margin: 20px;">
        <el-tab-pane label="积分管理" name="jifen">
          <div style="margin: 20px;">
            <el-form :inline="true" :model="userjifenForm" class="demo-form-inline">
              <el-form-item label="修改积分">
                <el-input v-model="userjifenForm.jifen" placeholder="修改积分"></el-input>
              </el-form-item>
              <el-form-item label="修改日志">
                <el-input v-model="userjifenForm.log" placeholder="修改日志"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="handlechangejifen">确定</el-button>
              </el-form-item>
            </el-form>
          </div>
          <el-table :data="userjifendetail.jifenls" empty-text="暂无数据" border style="width: 100%;">   
            <el-table-column prop="createTime" label="时间"></el-table-column>
            <el-table-column prop="taskName" label="日志"></el-table-column>
            <el-table-column label="积分变更">
              <template slot-scope="scope">
                <span>{{scope.row.score > 0 ? '+' : ''}}{{scope.row.score}}</span>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="物流管理" name="wuliu">
          <el-table :data="wuliuData" empty-text="暂无数据" border style="width: 100%;">   
            <el-table-column prop="receiptName" label="收货人" width="100"></el-table-column>
            <el-table-column prop="receiptPhone" label="手机号" width="150"></el-table-column>
            <el-table-column prop="address" label="地址"></el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span> -->
    </el-dialog>

  </div>
</template>

<script>

// import { formatData } from "./../../utils/index";
import { POST } from '../../api/index'
export default {

  data() {
    return {
      userinfotabname: 'jifen',
      tableData: [],
      formInline: {
        userid: '',
      },
      showUserJifen: false,
      userjifendetail: {
        jifenls: [
         
        ],
      },
      wuliuData: [
        {
          phonenumber: '17621666265',
          address: '上海市 徐汇区 宜州路188号华鑫科技园b8栋9楼 上海一条网络科技有限公司',
          name: '千千'
        }
      ],
      userjifenForm: {
        jifen: '',
        log: '',
      },
      pagination: {
        total: 0,
        pageSize: 10,
        pageNum: 1,
      }
    }
  },
  created() {
    this.getUserList()
  },
  methods: {
    currentpagenum(e) {
      this.pagination.pageNum = e
      this.getUserList()
    },
    nextPageUser() {
      this.pagination.pageNum++
      this.getUserList()
    },
    pervPageUser() {
      this.pagination.pageNum--
      this.getUserList()
    },
    async getUserDetail(row) {
      this.userjifendetail = JSON.parse(JSON.stringify(row))
      
      const res = await POST({
        url: 'api/common/queryAddress', 
        body: {
          userId: row.id,
        }
      })
      if (res.status == 200) {
        this.wuliuData = res.content
      } else {
        this.$message.error(res.msg);
      }
      const res1 = await POST({
        url: 'admin/queryScoreList', 
        body: {
          userId: row.id,
          pageNum: 1,
          pageSize: 1000,
          type: [1,2,3],
        }
      })
      if (res1.status == 200) {
        this.userjifendetail.jifenls = res1.content.scoreDetailDTOList.list
      } else {
        this.$message.error(res1.msg);
      }

      this.showUserJifen = true
      
    },
    async handleLahei(row) {
      const res = await POST({
        url: 'admin/user/disable', 
        body: {
          deleted: 1,
          userId: row.id,
        }
      })
      if (res.status == 200) {
        
        this.tableData.forEach(v => {
          if (v.id == row.id) {
            v.deleted = 1
          }
        })
      } else {
        this.$message.error(res.msg);
      }
    },
    async handlequxiaoLahei(row) {
      const res = await POST({
        url: 'admin/user/disable', 
        body: {
          deleted: 0,
          userId: row.id,
        }
      })
      if (res.status == 200) {
        
        this.tableData.forEach(v => {
          if (v.id == row.id) {
            v.deleted = 0
          }
        })
      } else {
        this.$message.error(res.msg);
      }
    },
    handleSearch() {

    },
    biaostyle(e) {
      if (e.row.isLagei) {
        return {
          background: '#f1f2f5'
        }
      }
    },
    async handlechangejifen() {
      if (!this.userjifenForm.jifen || isNaN(this.userjifenForm.jifen)) {
        this.$message.error('请输入正确的积分');
        return
      }
      if (!this.userjifenForm.log) {
        this.$message.error('请输入修改日志');
        return
      }
      // this.userjifendetail.jifenls.unshift({
      //   log: this.userjifenForm.log,
      //   jifen: parseInt(this.userjifenForm.jifen),
      // })
      // this.userjifenForm = {
      //   jifen: '',
      //   log: '',
      // }
      const res = await POST({
        url: 'admin/updateUserScore', 
        body: {
          userId: this.userjifendetail.id,
          score: this.userjifenForm.jifen,
          info: this.userjifenForm.log
        },
      })
      if (res.status == 200) {
        this.showUserJifen = false
        this.$message.success('成功');
        this.getUserList()
        this.userjifenForm = {
          log: '',
          jifen: '',
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    handleClickuserinfotab(tab, event) {
      console.log(tab, event);
    },
    async getUserList() {
      const body = {
          pageNum: this.pagination.pageNum,
          pageSize: this.pagination.pageSize,
        }
      this.formInline.userid && (body.userId = this.formInline.userid)
      const res = await POST({
        url: 'admin/queryUser', 
        body,
      })
      if (res.status == 200) {
        this.tableData = res.content.list
        this.pagination = {
          total: res.content.total,
          pageSize: this.pagination.pageSize,
          pageNum: this.pagination.pageNum
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    async getyonghushouhuodizhi() {
      // const res = await POST({url: 'common/queryAddress', body: {userId: id}})
    }
    
  },
}
</script>

<style>
.user_content {
  width: 100%;
  
}
</style>
