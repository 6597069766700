<template>
  <div class="order_content">
    <div style="margin: 20px;">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <!-- <el-form-item label="订单id">
          <el-input v-model="formInline.renwuid" placeholder="任务id"></el-input>
        </el-form-item> -->
        <el-form-item label="用户id">
          <el-input v-model="formInline.userid" placeholder="用户id"></el-input>
        </el-form-item>
        <!-- <el-form-item label="商品id">
          <el-input v-model="formInline.productid" placeholder="商品id"></el-input>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="handleSearch">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-tabs v-model="orderTabCurrent" type="card" @tab-click="orderTabCurrentClick">
      <el-tab-pane v-for="(v, index) in orderTab" :key="index" :label="v.value" :name="v.key">
        <el-table :data="tableData" empty-text="暂无数据" border style="width: 100%;">   
          <el-table-column e-column prop="orderNo" label="订单id"></el-table-column>
          <el-table-column label="商品图" width="120">
            <template slot-scope="scope">
              <el-image
                style="width: 100px; height: 100px"
                :src="scope.row.goodsDO.images[0]"
                fit="cover"></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="goodsDO.id" label="商品id"></el-table-column>
          <el-table-column prop="goodsDO.name" label="商品名"></el-table-column>
          <el-table-column prop="userId" label="用户id"></el-table-column>
          <el-table-column prop="status" label="状态">
            <template slot-scope="scope">
              <p>{{ scope.row.status == 0 ? '未发货' : '已发货' }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="deliveryNo" label="物流单号"></el-table-column>
          <el-table-column fixed="right" label="操作" width="300">
            <template slot-scope="scope">
              <el-button slot="reference" @click="bianji(scope.row)" type="primary" size="small">编辑</el-button>
              
            </template>
          </el-table-column>
        </el-table>
        <el-pagination 
          :page-size="pagination.pageSize" 
          :pager-count="11" 
          layout="prev, pager, next" 
          :total="pagination.total"
          @next-click="nextPageUser()"
          @prev-click="pervPageUser()"
          @current-change="currentpagenum"
          >
        </el-pagination>
  
      </el-tab-pane>
    </el-tabs>

    <el-dialog
      title="订单信息"
      :visible.sync="detailInfoShow"
      width="70%"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      >
      <div v-if="detailInfoShow">
        <el-descriptions :title="'订单id：'+detailInfo.orderNo"></el-descriptions>
        <el-descriptions title="用户信息">
          <el-descriptions-item label="用户id">{{detailInfo.userId}}</el-descriptions-item>
        </el-descriptions>
        <div style="margin-top: 20px;"></div>
        <el-descriptions title="商品信息">
          <el-descriptions-item label="id">{{detailInfo.goodsDO.id}}</el-descriptions-item>
          <el-descriptions-item label="商品名称">{{detailInfo.goodsDO.name}}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions title="">
          <el-descriptions-item label="商品图片">
            <div style="display: flex;">
              <el-image
                v-for="(v, index) in detailInfo.goodsDO.images"
                :key="index"
                style="width: 200px; height: 200px; margin: 10px;"
                :src="v"
                :preview-src-list="detailInfo.goodsDO.images"
                fit="cover"></el-image>
            </div>
          </el-descriptions-item>
        </el-descriptions>
        <el-form ref="form" :model="productFormData" label-width="80px">
          <el-form-item label="快递公司">
            <el-input v-model="productFormData.wuliugongsi"></el-input>
          </el-form-item>
          <el-form-item label="快递单号">
            <el-input v-model="productFormData.wuliu"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="updataDelivery">保存</el-button>
            <el-button @click="(detailInfoShow = false)">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>


<script>
import { POST } from '../../api/index'

export default {

  data() {
    return {
      detailInfoShow: false,
      orderTabCurrent: '1',
      formInline: {
        orderid: '',
        userid: '',
        productid: '',
      },
      detailInfo: null,
      orderTab: [{
          key: '1',
          value: '未发货'
        }, {
          key: '2',
          value: '已发货'
        }, 
      ],
      productFormData: {
        wuliugongsi: '',
        wuliu: '',
      },
      tableData: [],
      pagination: {
        total: 0,
        pageSize: 10,
        pageNum: 1,
      },
    }
  },
  created() {
    this.getpageData()
  },
  methods: {
    currentpagenum(e) {
      this.pagination.pageNum = e
      this.getpageData()
    },
    nextPageUser() {
      this.pagination.pageNum++
      this.getpageData()
    },
    pervPageUser() {
      this.pagination.pageNum--
      this.getpageData()
    },
    async getpageData() {
      const body = {
        pageSize: this.pagination.pageSize,
        pageNum: this.pagination.pageNum,
        orderStatus: this.orderTabCurrent,
      }
      if (this.formInline.userid) {
        body.userId = this.formInline.userid
      }
      const res = await POST({
        url: 'admin/queryOrderList', 
        body,
      })
      if (res.status == 200) {
        res.content.list.forEach((v) => {
        if (v.goodsDO.pictureUrl) {
          v.goodsDO.images = v.goodsDO.pictureUrl.split(',')
        }
      });
        this.tableData = res.content.list
        this.pagination.total = res.content.total
      } else {
        this.$message.error(res.msg);
      }
    },
    orderTabCurrentClick(e) {
      this.orderTabCurrent = e.name
      this.pagination.pageNum = 1
      this.getpageData()
    },
    handleSearch() {
      this.pagination.pageNum = 1
      this.getpageData()
    },
    bianji(e){
      this.detailInfo = e
      this.productFormData = {
        wuliu: e.deliveryNo,
        wuliugongsi: e.deliveryCompany,
      }
      this.detailInfoShow = true
    },
    async updataDelivery() {
      if (!this.productFormData.wuliugongsi) {
        this.$message.error('请输入快递公司');
        return true
      }
      if (!this.productFormData.wuliu) {
        this.$message.error('请输入快递单号');
        return true
      }
      const res = await POST({
        url: 'admin/updateDelivery', 
        body: {
          deliveryCompany: this.productFormData.wuliugongsi,
          deliveryNo: this.productFormData.wuliu,
          orderId: this.detailInfo.id
        },
      })
      if (res.status == 200) {
        this.pagination.pageNum = 1
        this.detailInfoShow = false
        this.getpageData()
      } else {
        this.$message.error(res.msg);
      }
    },
  }
}
</script>

<style>
.order_content {
  width: 100%;
}
</style>