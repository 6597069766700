


import axios from './resources';

export async function POST({
  url, 
  body, 
  showLoading=false,
  header={},
}) {
  console.log(header)
  const re = await _fetch(url, 'POST', body, showLoading, header);
  if (re.code == 10004) {
    localStorage.removeItem('token')
  }
  return re ? {
    content: re.value,
    msg: re.message,
    status: re.code,
  } : {
    content: {},
    msg: '未知错误',
    status: -1,
  }
}

export async function GET({
  url,
  showLoading=false,
  header={},
}) {
  const re = await _fetch(url, 'GET', {}, showLoading, header);
  return re ? {
    content: re.result,
    msg: re.msg,
    status: re.status,
    total: re.total,
    timestamp: re.timestamp
  } : {
    content: {},
    msg: '未知错误',
    status: -1,
    total: 0,
    timestamp: 0
  }
}

const _fetch = async (url, method, body, showLoading, header) => {

  const res = method === 'POST' ? await axios.post('/task/'+url, body, header) : await axios.get(url)
  if (res.code === 200) {
    return res
  } else {
    return res
  }
}

