<template>
  <div class="check_content">
    <div style="margin: 20px;">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <!-- <el-form-item label="任务id">
          <el-input v-model="formInline.renwuid" placeholder="任务id"></el-input>
        </el-form-item> -->
        <el-form-item label="用户id">
          <el-input v-model="formInline.userid" placeholder="用户id"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch">搜索</el-button>
        </el-form-item>
      </el-form>
      <el-tabs v-model="taskTabCurrent" type="card" @tab-click="taskTabCurrentClick">
        <el-tab-pane label="待审核" name="3"></el-tab-pane>
        <el-tab-pane label="审核通过" name="1"></el-tab-pane>
        <el-tab-pane label="审核未通过" name="2"></el-tab-pane>
      </el-tabs>
      <el-table :data="tableData" empty-text="暂无数据" border style="width: 100%;">   
        <el-table-column prop="taskId" label="任务id"></el-table-column>
        <el-table-column label="任务图" width="120">
          <template slot-scope="scope">
            <el-image
              style="width: 100px; height: 100px"
              :src="scope.row.pictureUrl"
              fit="cover"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="任务名"></el-table-column>
        <el-table-column prop="describe" label="任务简介"></el-table-column>
        <el-table-column prop="userId" label="用户id"></el-table-column>
        <el-table-column prop="nick" label="用户昵称"></el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button slot="reference" type="primary" size="small" @click="chkandetail(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination 
        :page-size="pagination.pageSize" 
        :pager-count="11" 
        layout="prev, pager, next" 
        :total="pagination.total"
        @next-click="nextPageUser()"
        @prev-click="pervPageUser()"
        @current-change="currentpagenum"
        >
    </el-pagination>
    </div>

    <el-dialog
      title="审核信息"
      :visible.sync="detailInfoShow"
      width="70%"
      :show-close="true"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      >
      <div v-if="detailInfoShow">
        <el-descriptions title="任务审核">
          <template slot="extra">
            <el-button v-if="taskTabCurrent == 3" type="danger" size="small" @click="shenherenwu(2)">不通过</el-button>
            <el-button v-if="taskTabCurrent != 1" type="primary" size="small" @click="shenherenwu(1)">通过</el-button>
          </template>
          <el-descriptions-item label="任务状态">{{zhuangtai[taskTabCurrent]}}</el-descriptions-item>
        </el-descriptions>
        <div style="margin-top: 20px;"></div>
        <el-descriptions title="任务信息">
          <el-descriptions-item label="任务id">{{detailInfo.taskId}}</el-descriptions-item>
          <el-descriptions-item label="任务名称">{{detailInfo.name}}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions title="">
          <el-descriptions-item label="任务描述">{{detailInfo.describe}}</el-descriptions-item>
        </el-descriptions>
        <div style="margin-top: 20px;"></div>
        <el-descriptions title="用户信息">
          <el-descriptions-item label="用户名">{{detailInfo.userId}}</el-descriptions-item>
          <el-descriptions-item label="用户昵称">{{detailInfo.nick}}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions title="" v-if="detailInfo.userLinkUrl">
          <el-descriptions-item label="相关链接">
            <a style="color: #3173f8;cursor: pointer;" v-clipboard:copy="detailInfo.userLinkUrl" v-clipboard:success="onCopy">{{detailInfo.userLinkUrl}}</a>
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions title="">
          <el-descriptions-item label="相关图片">
            <div style="display: flex;">
              <el-image
                style="width: 200px; height: 200px; margin: 10px;"
                :src="detailInfo.userPictureUrl"
                :preview-src-list="[detailInfo.userPictureUrl]"
                fit="cover"></el-image>
            </div>
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { POST } from '../../api/index'
export default {

  data() {
    return {
      detailInfoShow: false,
      taskTabCurrent: '3',
      zhuangtai: {
        3: '待审核',
        1: '审核通过',
        2: '未通过',
      },
      formInline: {
        renwuid: '',
        userid: '',
      },
      detailInfo: {},
      tableData: [],
      pagination: {
        total: 0,
        pageSize: 10,
        pageNum: 1,
      }
    }
  },
  created() {
    this.getpageData()
  },
  methods: {
    currentpagenum(e) {
      this.pagination.pageNum = e
      this.getpageData()
    },
    nextPageUser() {
      this.pagination.pageNum++
      this.getpageData()
    },
    pervPageUser() {
      this.pagination.pageNum--
      this.getpageData()
    },
    async shenherenwu(status) {
      const res = await POST({
        url: 'admin/passTask', 
        body: {
          userTaskId: this.detailInfo.userTaskId,
          status
        },
      })
      if (res.status == 200) {
        this.detailInfoShow = false
        this.pagination.pageNum = 1
        this.getpageData()
      } else {
        this.$message.error(res.msg);
      }
    },
    async getpageData() {
      const body = {
        pageSize: this.pagination.pageSize,
        pageNum: this.pagination.pageNum,
        status: this.taskTabCurrent,
      }
      if (this.formInline.userid) {
        body.userId = this.formInline.userid
      }
      const res = await POST({
        url: 'admin/queryUserTask', 
        body,
      })
      if (res.status == 200) {
        this.tableData = res.content.list
        this.pagination.total = res.content.total
      } else {
        this.$message.error(res.msg);
      }
    },
    handleSearch() {
      this.pagination.pageNum = 1
      this.getpageData()
    },
    taskTabCurrentClick(e) {
      this.taskTabCurrent = e.name
      this.pagination.pageNum = 1
      this.getpageData()
    },
    chkandetail(e) {
      this.detailInfo = e
      this.detailInfoShow = true
    },
    onCopy() {
      this.$message({
        message: '复制成功',
        type: 'success'
      });
    }
  }
}
</script>

<style scoped>
.check_content {
  width: 100%;
}
</style>
